<template>
  <div id="materialOutput">
    <!-- 顶部筛选 -->
    <div class="filter">
      <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">品名</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model.trim="listData.params.productName"
          @keyup.enter.native="getTableDataList"
        />
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">坯布指示单号</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model.trim="listData.params.orderNumber"
          @keyup.enter.native="getTableDataList"
        />
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">坯布货号</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model.trim="listData.params.articleNumber"
          @keyup.enter.native="getTableDataList"
        />
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">单据编号</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model.trim="listData.params.docNumber"
          @keyup.enter.native="getTableDataList"
        />
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">单据类型</div>
        <Select
          slot="list"
          style="width: 150px"
          v-model="listData.params.docType"
          @on-change="getTableDataList"
        >
          <Option v-for="item in typeList" :value="item" :key="item + 'type'">{{
            item
          }}</Option>
        </Select>
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">单据日期</div>
        <DatePicker
          v-model="listData.params.dateRange"
          format="yyyy-MM-dd"
          type="daterange"
          placeholder="请选择日期范围"
          style="width: 220px"
          @on-change="changeDate"
        ></DatePicker>
      </div>
      <div class="filterItem">
        <Button @click="customFilter"
          >自定义筛选
           <i v-if="!customShow" class="iconfont iconicon-xia"></i>
          <i v-if="customShow" class="iconfont iconicon-shang"></i>
        </Button>
      </div>
    </div>
    <!-- 自定义筛选选择 -->
    <div class="custom" v-if="customShow">
      <!-- <div class="triangle"></div> -->
      <div class="check" v-if="customSetting">
        <Checkbox
          :indeterminate="indeterminate"
          :value="checkAll"
          @click.prevent.native="handleCheckAll"
          >全选</Checkbox
        >
        <div class="border"></div>
        <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange">
          <Checkbox
            v-for="item in checkList"
            :key="item.index"
            :label="item.label"
          ></Checkbox>
        </CheckboxGroup>
        <div class="btn">
          <!-- <Button @click="cancelSetting">取消</Button> -->
          <Button type="primary" @click="confirmSetting">确定</Button>
        </div>
      </div>
      <!-- 自定义筛选确认后 -->
      <div class="searchSetting" v-if="alreadySetting">
        <div class="settingFilter">
          <div class="filterItem" v-for="item in selectedCheckList" :key="item.key">
            <div style="margin-right:10px">{{ item.label }}</div>
            <Input placeholder="请输入搜索内容" style="width: 212px" v-model.trim="listData.params[item.key]" @on-change="getTableDataList" />
          </div>
        </div>
      </div>
    </div>
    <div class="button">
      <div class="buttonRow">
        <div class="left">
          <div class="item" @click="refresh('main')">
            <i class="iconfont iconicon-shuaxin"></i>
            刷新
          </div>
          <div class="item" @click="syncTableData">
            <i class="iconfont iconicon-tongbu"></i>
            同步
          </div>
          <div class="item" @click="showTableTitleDialog">
            <i class="iconfont iconicon-chuansuokuang"></i>
            表格设置
          </div>
          <div class="item"></div>
          <div class="item" @click="exportMaterialOutList">
            <i class="iconfont iconicon-daochu"></i>
            导出
          </div>
          <div class="item" @click="getTableDataList">
            <i class="iconfont iconquerenshaixuan"></i>
            确认筛选
          </div>
        </div>
      </div>
    </div>
    <Table
      :columns="tableRealColumns"
      :data="tableData"
      border
      show-summary
      :summary-method="handleSummary"
        :loading="loading"
    ></Table>
    <Page
      class-name="page"
      :total="listDataTotal"
      show-elevator
      show-sizer
      show-total
      :page-size="listData.pageSize"
      :current="listData.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
    <el-drawer
      title="原材料出仓细表"
      :visible.sync="drawerShow"
      direction="btt"
      size="570px"
    >
      <div class="button">
        <div class="buttonRow">
          <div class="left">
            <div class="item" @click="refresh('detail')">
              <i class="iconfont iconicon-shuaxin"></i>
              刷新
            </div>
            <div class="item" @click="showTableTitleDialogDetail">
              <i class="iconfont iconicon-chuansuokuang"></i>
              表格设置
            </div>
          </div>
        </div>
      </div>
      <Table
        :columns="detailsTableRealColumns"
        :data="detailsTableData"
        border
        show-summary
        :summary-method="handleSummaryDetail"
        :loading="loadingDetail"
      ></Table>
      <Page
        class-name="page"
        :total="detailsListDataTotal"
        show-elevator
        show-sizer
        show-total
        :page-size="detailsListData.pageSize"
        :current="detailsListData.pageNum"
        @on-page-size-change="pageSizeDetailsChange"
        @on-change="pageNumberDetailsChange"
      />
    </el-drawer>
    <!-- 穿梭框的弹出框 -->
    <TableSetting
      :routerPath="routerPath"
      :theadDateReal="bindTheadDateReal"
      @tableDataList="bindTableDataList"
      :chuansuoDialogVisible.sync="chuansuoDialogVisible"
    ></TableSetting>
  </div>
</template>

<script>
import TableSetting from "../../components/home/tableSetting.vue";

export default {
  components: {
    TableSetting
  },
  data() {
    return {
      loading: true,
      loadingDetail: true,
      routerPath: "",
      searchList: [
        {
          index: 1,
          name: "坯布指示单号"
        },
        {
          index: 2,
          name: "坯布货号"
        },
        {
          index: 3,
          name: "单据编号"
        }
      ],
      bindTheadDateReal: [],
      bindTableDataList: [],
      typeList: [],
      chuansuoDialogVisible: false,
      customShow: false,
      customSetting: true,
      alreadySetting: false,
      indeterminate: false,
      checkAll: false,
      checkAllGroup: [],
      selectedCheck: [], // 已选中的可选条件组成的数组
      selectedCheckList: [], // 已选中的可选条件组成的数组list
      checkList: [
        {
          index: 1,
          label: "单位名称",
          key: 'unitName'
        },
        {
          index: 2,
          label: "担当",
          key: 'takeOn'
        },
        {
          index: 3,
          label: "应付单号",
          key: 'payableNumber'
        },
      ],
      listData: {
        params: {
          orderNumber: "",
          articleNumber: "",
          docNumber: "",
          docType: "",
          unitName: "",
          takeOn: "",
          payableNumber: "",
          productName: "",
          dateRange: [],
          docDateStart: "",
          docDateEnd: ""
        },
        pageSize: 10,
        pageNum: 1
      },
      currentId: "",
      listDataTotal: 0,
      summaryMainList: {
        ztmoClothQuantityTotal: "",
        ztmoTotalNumTotal: "",
        ztmoNumberClaimsTotal: ""
      },
      tableRealColumns: [],
      tableColumns: [
        {
          title: "序号",
          render: (h, params) => {
            return h(
              "span",
              params.index +
                (this.listData.pageNum - 1) * this.listData.pageSize +
                1
            );
          },
          width: 65,
          align: "center"
        },
        {
          title: "操作",
          key: "action",
          width: 65,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    fontSize: "14px",
                    color: "#256DE6",
                    cursor: "pointer"
                  },
                  on: {
                    click: () => {
                      this.drawerShow = true;
                      this.currentId = params.row.ztmoId;
                      this.getTableDataList1();
                    }
                  }
                },
                "详情"
              )
            ]);
          }
        },
        {
          title: "单据类型",
          key: "ztmoDocType",
          minWidth: 130
        },
        {
          title: "单据编号",
          key: 'ztmoDocNumber',
          minWidth: 130
        },
        {
          title: "坯布指示单号",
          key: "ztmoOrderNumber",
          minWidth: 130
        },
        {
          title: "单位名称",
          key: "ztmoUnitName",
          minWidth: 200
        },
        {
          title: "品名 ",
          key: "ztmoProductName",
          minWidth: 100
        },
        {
          title: "原料规格",
          key: "ztmoiSpecification",
          minWidth: 300
        },
        {
          title: "匹数合计",
          key: "ztmoClothQuantity",
          minWidth: 100
        },
        {
          title: "单位编号",
          key: "ztmoUnitNumber",
          minWidth: 100
        },
        {
          title: "数量合计",
          key: "ztmoTotalNum",
          minWidth: 100
        },
        {
          title: "织造要求数量",
          key: "ztmoNumberClaims",
          minWidth: 125
        },
        {
          title: "详细描述",
          key: "ztmoDetails",
          minWidth: 200
        },
        {
          title: "应付单号",
          key: "ztmoPayableNumber",
          minWidth: 100
        },
        {
          title: "坯布货号",
          key: "ztmoArticleNumber",
          minWidth: 130
        },
        {
          title: "担当",
          key: "ztmoTakeOn",
          minWidth: 100
        },
        {
          title: "编辑日",
          key: "ztmoEditDate",
          minWidth: 160
        },
        {
          title: "状态",
          key: "ztmoStatus",
          minWidth: 160
        },
        {
          title: "单据日期",
          key: "ztmoEditDate",
          minWidth: 160
        },
        {
          title: "编辑者",
          key: "ztmoEditUser",
          minWidth: 160
        },
      ],
      tableData: [],
      theadDateReal: [],
      drawerShow: false,
      detailsListData: {
        pageSize: 8,
        pageNum: 1
      },
      detailsListDataTotal: 0,
      summaryDetailList: {
        ztmoiClothNumTotal: "",
        ztmoiNumTotal: ""
      },
      detailsTableRealColumns: [],
      detailsTableColumns: [
        {
          title: "序号",
          render: (h, params) => {
            return h(
              "span",
              params.index +
                (this.detailsListData.pageNum - 1) *
                  this.detailsListData.pageSize +
                1
            );
          },
          width: 65,
          align: "center"
        },
        {
          title: "织造单号",
          key: "ztmoiDocType",
          minWidth: 120
        },
        {
          title: "进仓单号",
          key: "ztmoiEntryCargoNum",
          minWidth: 120
        },
        {
          title: "公司订单号",
          key: "ztmoiCompanyOrderNum",
          minWidth: 120
        },
        {
          title: "原料名称",
          key: "name",
          minWidth: 120
        },
        {
          title: "批次",
          key: "ztmoiBatch",
          minWidth: 100
        },
        {
          title: "颜色",
          key: "ztmoiColor",
          minWidth: 100
        },
        {
          title: "色号",
          key: "ztmoiColorNum",
          minWidth: 125
        },
        {
          title: "加工色号",
          key: "ztmoiProcessColorNum",
          minWidth: 100
        },
        {
          title: "加工颜色",
          key: "ztmoiProcessColor",
          minWidth: 100
        },
        {
          title: "缸口",
          key: "ztmoiVatNum",
          minWidth: 100
        },
        {
          title: "件数",
          key: "ztmoiClothNum",
          minWidth: 100
        },
        {
          title: "规格",
          key: "ztmoiSpecification",
          minWidth: 300
        },
        {
          title: "数量(KG)",
          key: "ztmoiNum",
          minWidth: 100
        },
        {
          title: "详细描述",
          key: "ztmoiDetails",
          minWidth: 100
        }
      ],
      detailsTableData: [],
      detailsTheadDateReal: []
    };
  },
  created() {
    this.getTableDataList();
    this.getDocTypeList();
  },
  methods: {
    pageSizeChange(pageSize) {
      this.listData.pageSize = pageSize;
      this.listData.pageNum = 1;
      this.getTableDataList();
    },
    pageNumberChange(pageNum) {
      this.listData.pageNum = pageNum;
      this.getTableDataList();
    },
    pageSizeDetailsChange(pageSize) {
      this.detailsListData.pageSize = pageSize;
      this.detailsListData.pageNum = 1;
      this.getTableDataList1();
    },
    pageNumberDetailsChange(pageNum) {
      this.detailsListData.pageNum = pageNum;
      this.getTableDataList1();
    },
    // 主表格显示弹出框
    showTableTitleDialog() {
      this.routerPath =
        "/dtsum/zongtong/material/materialController/materialOutSearch";
      this.bindTheadDateReal = this.tableRealColumns;
      this.bindTableDataList = this.getTableDataList;
      this.chuansuoDialogVisible = true;
    },
    showTableTitleDialogDetail() {
      this.routerPath =
        "/dtsum/zongtong/material/materialController/materialOutDetail";
      this.bindTheadDateReal = this.detailsTableRealColumns;
      this.bindTableDataList = this.getTableDataList1;
      this.chuansuoDialogVisible = true;
    },
    handleCheckAll() {
      if (this.indeterminate) {
        this.checkAll = false;
      } else {
        this.checkAll = !this.checkAll;
      }
      this.indeterminate = false;
      if (this.checkAll) {
        this.checkAllGroup = this.checkList.map(function(item) {
          return item.label;
        });
      } else {
        this.checkAllGroup = [];
      }
    },
    checkAllGroupChange(data) {
      if (data.length === this.checkList.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
    },
    // 获取单据类型接口
    getDocTypeList() {
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/commonSelect",
        method: "get",
        params: {
          flag: "materialouttype"
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.typeList = res.data.body.selectList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
   // 点击自定义筛选
    customFilter() {
      if (this.selectedCheck.length > 0) {
        this.customShow = true;
        if (this.alreadySetting) {
          this.customSetting = true;
          this.alreadySetting = false;
          this.checkAllGroup = this.selectedCheck;
          if (this.checkList.length !== this.checkAllGroup.length) {
            this.indeterminate = true;
          } else {
            this.indeterminate = false;
            this.checkAll = true;
          }
        } else {
          this.customSetting = false;
          this.alreadySetting = true;
        }
      } else {
        this.customShow = !this.customShow;
        this.checkAllGroup = this.selectedCheck;
        this.indeterminate = false;
      }
    },
    // 确定自定义筛选
    confirmSetting() {
      this.selectedCheck = this.checkAllGroup;
      if (this.selectedCheck.length > 0) {
        this.customSetting = false;
        this.alreadySetting = true;
        this.customShow = true;
        var arr = [];
        this.selectedCheck.forEach((item) => {
          this.checkList.forEach((item1) => {
            if (item === item1.label) {
              arr.push(item1);
            }
          });
        });
        this.selectedCheckList = arr;
      } else {
        this.customSetting = true;
        this.alreadySetting = false;
        this.customShow = false;
        this.indeterminate = false;
      }
    },
    // 日期改变进行拆分
    changeDate() {
      const date = this.listData.params.dateRange;
      if (date[0] !== "" && date[0] !== null) {
        this.listData.params.docDateStart = this.moment(date[0]).format(
          "YYYY-MM-DD"
        );
        this.listData.params.docDateEnd = this.moment(date[1]).format(
          "YYYY-MM-DD"
        );
      } else {
        this.listData.params.docDateStart = "";
        this.listData.params.docDateEnd = "";
      }
      this.getTableDataList();
    },
    // 刷新表格
    refresh(params) {
      if (params === "main") {
        this.loading = true;
        this.getTableDataList();
      } else {
        this.loadingDetail = true;
        this.getTableDataList1();
      }
    },
    // 同步表格数据
    syncTableData() {
      this.loading = true;
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/synTable",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("同步成功！");
            this.getTableDataList();
          } else {
            this.$Message.warning("同步失败！");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取原材料出仓列表
    getTableDataList() {
      this.tableRealColumns.length = 0;
      this.axios({
        url: "/dtsum/zongtong/material/materialController/materialOutSearch",
        method: "get",
        params: this.listData
      })
        .then((res) => {
          if (res.data.success === 1) {
            if (this.tableRealColumns.length === 0) {
              this.tableRealColumns.push(this.tableColumns[0]);
              this.tableRealColumns.push(this.tableColumns[1]);
              for (let i = 0; i < res.data.body.userFilds.length; i++) {
                for (let j = 0; j < this.tableColumns.length; j++) {
                  if (
                    res.data.body.userFilds[i].field ===
                    this.tableColumns[j].key
                  ) {
                    this.tableRealColumns.push(this.tableColumns[j]);
                  }
                }
              }
              this.tableData = JSON.parse(
                JSON.stringify(res.data.body.materialDetail.list)
              );
              this.listDataTotal = res.data.body.materialDetail.total;
              this.listData.pageSize = res.data.body.materialDetail.pageSize;
              this.listData.pageNum = res.data.body.materialDetail.pageNum;
              this.summaryMainList.ztmoClothQuantityTotal =
                res.data.body.ztmoClothQuantityTotal;
              this.summaryMainList.ztmoTotalNumTotal =
                res.data.body.ztmoTotalNumTotal;
              this.summaryMainList.ztmoNumberClaimsTotal =
                res.data.body.ztmoNumberClaimsTotal;
            }
          }
            this.loading = false;
        })
        .catch((err) => {
          console.log(err);
            this.loading = false;
        });
    },
    handleSummary({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "累计"
          };
        } else if (key === "ztmoClothQuantity") {
          sums[key] = {
            key,
            value: this.summaryMainList.ztmoClothQuantityTotal
          };
        } else if (key === "ztmoTotalNum") {
          sums[key] = {
            key,
            value: this.summaryMainList.ztmoTotalNumTotal
          };
        } else if (key === "ztmoNumberClaims") {
          sums[key] = {
            key,
            value: this.summaryMainList.ztmoNumberClaimsTotal
          };
        } else {
          sums[key] = {
            key,
            value: ""
          };
        }
      });
      return sums;
    },
    // 导出原材料出仓列表
    exportMaterialOutList() {
      const data = this.listData.params;
      window.location.href = `${this.baseUrl}/dtsum/zongtong/material/materialController/exportMaterialOutList?orderNumber=${data.orderNumber}&articleNumber=${data.articleNumber}&docNumber=${data.docNumber}&docType=${data.docType}&docDateStart=${data.docDateStart}&docDateEnd=${data.docDateEnd}&unitName=${data.unitName}&takeOn=${data.takeOn}&payableNumber=${data.payableNumber}&productName=${data.productName}`;
    },
    // 出仓细表
    getTableDataList1() {
      this.detailsTableRealColumns.length = 0;
      this.axios({
        url: "/dtsum/zongtong/material/materialController/materialOutDetail",
        method: "get",
        params: {
          ztmoId: this.currentId,
          pageSize: this.detailsListData.pageSize,
          pageNum: this.detailsListData.pageNum
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            if (this.detailsTableRealColumns.length === 0) {
              this.detailsTableRealColumns.push(this.detailsTableColumns[0]);
              for (let i = 0; i < res.data.body.userFilds.length; i++) {
                for (let j = 0; j < this.detailsTableColumns.length; j++) {
                  if (
                    res.data.body.userFilds[i].field ===
                    this.detailsTableColumns[j].key
                  ) {
                    this.detailsTableRealColumns.push(
                      this.detailsTableColumns[j]
                    );
                  }
                }
              }
              this.detailsTableData = res.data.body.materialOutDetail.list;
              this.detailsListDataTotal = res.data.body.materialOutDetail.total;
              this.detailsListData.pageSize =
                res.data.body.materialOutDetail.pageSize;
              this.detailsListData.pageNum =
                res.data.body.materialOutDetail.pageNum;
              this.summaryDetailList.ztmoiClothNumTotal =
                res.data.body.ztmoiClothNumTotal;
              this.summaryDetailList.ztmoiNumTotal =
                res.data.body.ztmoiNumTotal;
            }
          }
            this.loadingDetail = false;
        })
        .catch((err) => {
          console.log(err);
            this.loadingDetail = false;
        });
    },
    handleSummaryDetail({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "累计"
          };
        } else if (key === "ztmoiClothNum") {
          sums[key] = {
            key,
            value: this.summaryDetailList.ztmoiClothNumTotal
          };
        } else if (key === "ztmoiNum") {
          sums[key] = {
            key,
            value: this.summaryDetailList.ztmoiNumTotal
          };
        } else {
          sums[key] = {
            key,
            value: ""
          };
        }
      });
      return sums;
    }
  }
};
</script>

<style lang="scss" scoped>
#materialOutput {
  .filter {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    font-size: $--fontsize-small;
    .filterItem {
      display: inline-flex;
      margin-right: 50px;
      margin-bottom: 20px;
      height: 32px;
      line-height: 32px;
      i {
        margin-left: 9px;
      }
      &:last-child {
        width: 132px;
        margin-right: 0px;
        button {
          position: absolute;
          right: 0px;
        }
      }
    }
  }
  .custom {
    position: relative;
    width: 100%;
    // min-height: 95px;
    background: #F4F7FE;
    margin-top: -35px;
    margin-bottom: 39px;
    padding: 16px 8px;
    .triangle {
      position: absolute;
      top: -14px;
      right: 60px;
      width: 0px;
      height: 0px;
      border-top: 0px solid transparent;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 14px solid #f5f5f5;
    }
    .border {
      display: inline-block;
      width: 1px;
      height: 14px;
      background: #dcdada;
      margin-right: 20px;
      vertical-align: middle;
    }
    .ivu-checkbox-wrapper {
      margin-right: 20px;
    }
    .ivu-checkbox-group {
      display: inline-block;
    }
    .btn {
      margin-top: 17px;
      text-align: right;
    }
  }
  .searchSetting {
    display: flex;
    justify-content: space-between;
    margin-bottom: -20px;
    .settingFilter {
      display: flex;
      flex-wrap: wrap;
      font-size: $--fontsize-small;
      .filterItem {
        display: inline-flex;
        margin-right: 50px;
        margin-bottom: 20px;
        height: 32px;
        line-height: 32px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .settingBtn {
      display: flex;
      line-height: 32px;
      .border {
        width: 1px;
        height: 14px;
        margin-right: 14px;
        background: #dcdada;
        margin-top: 9px;
      }
      .changebtn {
        color: #256de6;
        cursor: pointer;
      }
    }
  }
  .button {
    font-size: $--fontsize-small;
    margin-bottom: 20px;
    margin-top: -20px;
    .buttonRow {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      .left {
        .item {
          display: inline-block;
          margin-right: 30px;
          color: $--color-blue-standard;
          cursor: pointer;
          &:nth-child(3) {
            margin-right: 20px;
          }
          &:nth-child(4) {
            width: 1px;
            height: 14px;
            background: #dcdada;
            margin-right: 20px;
            vertical-align: middle;
          }
        }
      }
      .right {
        .item {
          margin-left: 10px;
        }
        .filterButton{
          width:130px;
          height:32px;
          line-height:32px;
          text-align: center;
        }
      }
    }
  }
  .page {
    margin-top: 10px;
    text-align: center;
  }
}
</style>
<style lang="scss">
#materialOutput {
  .el-drawer {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    .el-drawer__header {
      margin-bottom: 12px;
      padding: 20px 30px 0px 30px;
      font-size: 16px;
      color: #212b36;
    }
    .el-drawer__body {
      overflow: auto;
      padding: 0px 30px 30px 30px;
      .button {
        margin-top: 0px;
        margin-bottom: 17px;
      }
    }
  }
}
</style>
